<template>
  <nav class="mb-10 mt-8 flex w-fit flex-wrap gap-6 lg:m-0 lg:mx-auto">
    <NuxtLink
      v-for="item in data"
      :key="item.id"
      :to="item.reference ? localePathByType('PageRecord', item.reference.slug) : item.externalLink"
      :target="item.externalLink ? '_blank' : undefined"
      class="w-fit text-xs underline hover:no-underline"
      noPrefetch
    >
      {{ item.title }}
    </NuxtLink>

    <button class="w-fit text-xs underline hover:no-underline" @click="handleButtonClick">
      {{ $t('cookies.comply.manage') }}
    </button>
  </nav>
</template>

<script setup lang="ts">
import type { MenuFooterRecord } from '~/types/generated'
import { useCookiesStore } from '~/stores/CookiesStore'

const store = useCookiesStore()

function handleButtonClick() {
  store.setIsBannerVisible(true)
  store.setShowPreferences(true)
}

const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Array as PropType<MenuFooterRecord[]>
  }
})
</script>
