<template>
  <div class="flex w-fit gap-4">
    <a
      v-for="item in links"
      :key="item.name"
      target="_blank"
      rel="noopener noreferrer"
      :href="item.href"
      :title="item.title"
    >
      <Icon :name="item.icon" color="white" />
    </a>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '~/components/media/Icon.vue'
const { t } = useI18n()

const links = [
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/company/sti-corporate',
    icon: 'linkedin' as IconName,
    title: t('social.links.linkedin')
  }
]
</script>
