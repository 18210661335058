<template>
  <div>
    <component
      :is="data.reference !== null ? NuxtLink : data.externalLink ? NuxtLink : 'span'"
      :to="
        data.reference
          ? localePath(`/${data.reference.slug}`)
          : data.externalLink
          ? data.externalLink
          : undefined
      "
      noPrefetch
      :target="data.externalLink ? '_blank' : undefined"
      class="mb-5 block text-base font-bold uppercase"
    >
      {{ data.title }}
    </component>
    <ul v-if="Array.isArray(data.sublinks) && data.sublinks.length" class="text-sm">
      <li v-for="item in data.sublinks" :key="item.id">
        <NuxtLink
          :to="
            item.reference ? localePathByType('PageRecord', item.reference.slug) : item.externalLink
          "
          noPrefetch
          class="my-2 block w-fit"
          >&angrt; {{ item.title }}
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { MenuFooterRecord } from '~/types/generated'

const NuxtLink = resolveComponent('NuxtLink')
const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Object as PropType<MenuFooterRecord>,
    default: () => {}
  }
})
</script>
