<template>
  <footer class="bg-neutral-800 pb-20 pt-14 text-white lg:pb-[3.25rem] lg:pt-16">
    <div class="container grid grid-cols-6 gap-x-4 lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
      <div class="col-span-6 mb-14 lg:col-span-2 lg:mb-0">
        <Logo color="white" with-text class="h-32 w-16" />
      </div>
      <div class="col-span-6 lg:col-span-2">
        <FooterCountries v-if="isDesktop" :data="data.allCountries" class="hidden lg:flex" />
        <AccordionSitemap v-if="!isDesktop" class="lg:hidden" :data="[1]">
          <template #label> STI GROUP </template>
          <template #content>
            <ul class="overflow-hidden">
              <li>
                <NuxtLink :to="$t('footer.locationsLink')" class="mt-2 inline-block w-fit text-sm">
                  &angrt; STI Group</NuxtLink
                >
              </li>
              <li v-for="item in data.allCountries" :key="item.id">
                <NuxtLink
                  :key="item.id"
                  class="mb-2 w-fit text-sm last:mb-0"
                  :to="{ path: $t('footer.locationsLink'), query: { name: item.name } }"
                >
                  &angrt; {{ item.name }}
                </NuxtLink>
              </li>
            </ul>
          </template>
        </AccordionSitemap>
      </div>
      <div class="col-span-6 lg:col-span-8">
        <SiteMap v-if="isDesktop" :data="largeMenuItems" class="hidden lg:grid" />
        <AccordionSitemap v-if="!isDesktop" class="mb-12 lg:hidden" :data="largeMenuItems">
          <template #label="labelProps">
            {{ labelProps.item.title }}
          </template>
          <template #content="contentProps">
            <ul
              v-if="Array.isArray(contentProps.item.sublinks) && contentProps.item.sublinks.length"
              class="overflow-hidden text-sm"
            >
              <li v-if="contentProps.item.reference !== null">
                <NuxtLink
                  :to="
                    contentProps.item.reference
                      ? localePath(`/${contentProps.item.reference.slug}`)
                      : contentProps.item.externalLink
                        ? contentProps.item.externalLink
                        : undefined
                  "
                  noPrefetch
                  :target="contentProps.item.externalLink ? '_blank' : undefined"
                  class="mt-2 inline-block w-fit text-sm"
                >
                  &angrt; {{ contentProps.item.title }}
                </NuxtLink>
              </li>
              <li v-for="sublink in contentProps.item.sublinks" :key="sublink.id">
                <NuxtLink
                  :to="
                    sublink.reference
                      ? localePathByType('PageRecord', sublink.reference.slug)
                      : props.item.externalLink
                  "
                  noPrefetch
                  class="my-2 block w-fit"
                  >&angrt; {{ sublink.title }}
                </NuxtLink>
              </li>
            </ul>
          </template>
        </AccordionSitemap>
      </div>
      <div class="col-span-6 lg:col-span-3">
        <span class="whitespace-nowrap text-2xl">&copy; <b>STI Corporate S.p.A.</b></span>
      </div>
      <div class="col-span-7 flex items-center">
        <FooterLinks :data="smallMenuItems" />
      </div>
      <div class="col-span-6 flex items-center gap-6 pb-10 lg:col-span-2 lg:pb-0">
        <LanguageDropdown class="w-fit lg:mx-auto lg:mr-0" />
        <Social />
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import type { MenuFooterRecord } from '~/types/generated'
const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const { isDesktop } = useDevice()
const { localePathByType } = useLocalePathByType()
const localePath = useLocalePath()

const smallMenuItems = computed(() =>
  props.data.allMenuFooters.filter((el: MenuFooterRecord) => !el.large)
)
const largeMenuItems = computed(() =>
  props.data.allMenuFooters.filter((el: MenuFooterRecord) => el.large)
)
</script>
