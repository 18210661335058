<template>
  <nav ref="accordion">
    <div
      v-for="(item, index) in data" :key="index"
      class="border-b border-neutral-300 py-4 text-white last:mb-0"
      :tabindex="index"
    >
      <div class="flex cursor-pointer items-center justify-between text-base" @click="expandAccordion(index)">
        <span v-if="item" class="font-bold uppercase">
          <slot name="label" :item="item" />
        </span>
        <Icon
          :name="getIconName(activeIndex === index)"
          class="transition-all duration-300"
          :class="activeIndex === index && 'rotate-180'"
          color="white" />
      </div>

      <div
        class="grid transform-gpu transition-all duration-500"
        :class="activeIndex === index ? 'opacity-1 mt-1 grid-rows-[1fr]' : 'grid-rows-[0fr] opacity-0'"
      >
        <slot name="content" :item="item" />
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
defineEmits(['user-action:email-click', 'user-action:phone-click'])

defineProps({
  data: {
    type: Array,
    default: () => []
  }
})
const activeIndex = ref<number | null>(null)

function getIconName(isActive: boolean) {
  return isActive ? 'minus' : 'plus'
}

function expandAccordion(idx: number | null) {
  activeIndex.value = activeIndex.value !== idx ? idx : null
}

const accordion = ref(null)
onClickOutside(accordion, () => expandAccordion(null))
</script>
